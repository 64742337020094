import React from 'react'
import './Hero.css'
import Group from '../../img/group.jpg'
import Footer from '../Footer/Footer'
import ComputeacherPage from '../ComputeacherPage/ComputeacherPage'
import DeveloperList from '../DeveloperList/DeveloperList'
import InfoSection from '../InfoSection/InfoSection'
// import { useAuth0 } from '@auth0/auth0-react';
// import Typewriter from "typewriter-effect";
// import Footer from '../Footer/Footer';
// import Upcoming from '../Upcoming/Upcoming';


const Hero = (props) => {
  // const { isAuthenticated } = useAuth0();

  // const message = () => {
  //   alert("Please Login");
  // }

  return (
    <>
      <div className="hero">
        <div className="hero-left">
          <div className='hero-text'>
            <h1>Escape the recruitment rut. Embrace innovation. Find your software rockstars.</h1>
            <p>Hire skilled remote developers: tech-savvy, great communicators, competitive rates, your timezone.</p>
          </div>
          {/* Add more text or components here as needed */}
          {/* Input and Button */}
          <div className="input-group">
            <input type="text" placeholder="Enter your email" />
            <button type="button">Recruit Today</button>
          </div>

          <div className="input-group" style={{marginTop: '50px' }}>
          Unlock the full potential of your projects with our top-tier remote developers. Start building your dream team today.
          </div>

        </div>
        <div className="hero-right" style={{marginBottom: '150px' }}>
          <img src={Group} alt="Descriptive Alt Text" />
          {/* Replace 'path_to_your_image.jpg' with the actual image path */}
        </div>
      </div>
      <ComputeacherPage/>
      <DeveloperList/>
      <InfoSection/>
      <Footer name = "CompuTeacher"/>
    </>
  )
}

export default Hero
