import React from 'react'
import DeveloperListComponent from '../DeveloperListComponent/DeveloperListComponent'
import { Container } from 'reactstrap';
import './DeveloperList.css'

const DeveloperList = () => {

    const skills = ["Node.js", "React.js", "Python", "AWS", "JavaScript", "React Native", "PostgreSQL", "Ruby on Rails", "Java", "Angular", "Golang", "PHP", "Android", "Django", "iOS", "Laravel Developer", "Magento", "ASP.NET", "C#", "React/Node"];
    const roles = ["ML Engineers", "Full-Stack Developers", "Back-End Developers", "Front-End Developers", "Mobile Developers", "App Developers"];
    const positions = ["Software Developers", "Senior Engineers", "Architects", "Tech Leads", "Director of Engineering", "VP of Engineering"];

    return (
        <div className='developerlist'>
            <Container>
                <h2 style={{fontSize: '22px'}}>Hire Computeacher Developers</h2>
                <DeveloperListComponent title="Based on Skills" items={skills} />
                <DeveloperListComponent title="Based on Role" items={roles} />
                <DeveloperListComponent title="Based on Career Trajectory" items={positions} />
            </Container>
        </div>
    )
}

export default DeveloperList