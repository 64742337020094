import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import './DeveloperListComponent.css'

const DeveloperListComponent = ({ title, items }) => (
    <div className='DeveloperListComponent'>
        <Container style={{marginTop: '50px'}}>
            <h4 style={{fontSize: '15px', fontWeight: 'bold'}}>{title}</h4>
            <Row>
                {items.map((item, index) => (
                    <Col key={index} md={2} sm={4} xs={4} className="mb-3">
                        <Button
                            block
                            className="button"
                            style={{
                                maxWidth: '150px',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}>{item}</Button>
                    </Col>
                ))}
            </Row>
        </Container>
    </div>
);

export default DeveloperListComponent;
