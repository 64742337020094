import React from 'react'
import './ComputeacherPage.css'
import Lang from '../../img/all-lang.jpg'
import { Container, Row, Col } from 'react-bootstrap';

const ComputeacherPage = () => {
  return (
    <div className='computeacherpage'>
      <Container fluid className="custom-margin">
        <Row className="align-items-center">
          <Col md={6} className="leftcolumn">
            <div className="content mx-5 my-4 mt-5">
              <h1>High quality/cost ratio</h1> <br /><br />
              <h4>Hire rigorously vetted developers at very low cost</h4>
              <h6>Access the top talent from a global pool of developers who have applied to Computeacher.</h6><br />
              <h4>100+ skills available</h4>
              <h6>Hire React, Node, Python, Angular, Swift, React Native, Android, Java, Rails, Golang, DevOps, ML, Data Engineers, and more.</h6><br />
              <h4>Try Risk Free</h4>
              <h6>Pay nothing if you choose to leave within two weeks.</h6>
            </div>
          </Col>
          <Col md={6} className="rightcolumn">
            <div className="image-container mx-5 my-4 mt-5">
              {/* Place your image here */}
              <img src={Lang} alt="Descriptive Alt Text" />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default ComputeacherPage