import React from 'react'
import './Footer.css'
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';

const Footer = (props) => {
    const darkBlue = "var(--blue)";
    return (
        <footer className="footer text-center text-lg-start">
            <Container>
                <Row className="py-5 align-items-center">
                    <Col lg={4} md={4} className="mb-3 mb-md-0">
                        <h5 className="text-uppercase">Follow us</h5>
                        <a href="https://facebook.com" className="me-4" style={{ color: darkBlue }}>
                            <FontAwesomeIcon icon={faFacebookF} />
                        </a>
                        <a href="https://twitter.com" className="me-4" style={{ color: darkBlue }}>
                            <FontAwesomeIcon icon={faTwitter} />
                        </a>
                        <a href="https://instagram.com" className="me-4" style={{ color: darkBlue }}>
                            <FontAwesomeIcon icon={faInstagram} />
                        </a>
                        {/* LinkedIn Icon */}
                        <a href="https://www.linkedin.com/company/computeacher/" target="blank" style={{ color: darkBlue }}>
                            <FontAwesomeIcon icon={faLinkedinIn} />
                        </a>
                    </Col>
                    <Col lg={4} md={4} className="mb-3 mb-md-0">
                        {/* Your logo and company name here */}
                        <h5 className="text-uppercase">{props.name}</h5>
                    </Col>
                    <Col lg={4} md={4} className="mb-3 mb-md-0">
                        <h5>Contact us</h5>
                        <h6>Email: info@computeacher.in</h6>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}

export default Footer