import './App.css';
import React from 'react'
import Header from './components/Header/Header';
import Hero from './components/Hero/Hero';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Contact from './components/Contact/Contact';


function App() {
  return (
      <Router>
        <Header compu = "Compu" teacher = "Teacher"/>
        <Routes>
          <Route path="/" element={<Hero compu = "COMPU" teacher = "TEACHER" />}>
          </Route>
          <Route path="/Contact" element={<Contact />}>
          </Route>
        </Routes>
      </Router>
  );
}

export default App;
