import React from 'react'
// import { useState } from 'react'
import './Contact.css'
// import firebase from 'firebase/app';
// import 'firebase/firestore';

const Contact = () => {
  // const db = firebase.firestore();

  // Access the "Feedback" collection
  // const usersRef = db.collection('Feedback');

  // const [feedback, setFeedback] = useState({
  //   name: "",
  //   email: "",
  //   phone: "",
  //   message: ""
  // });

  // let name, value;
  // const getFeedbackData = (e) => {
  // usersRef.get().then((querySnapshot) => {
  //   querySnapshot.forEach((doc) => {
  //     console.log(`${doc.id} => Name: ${doc.data().name}, Email: ${doc.data().email}`);
  //   });
  // });

  //   name = e.target.name;
  //   value = e.target.value;
  //   setFeedback({ ...feedback, [name]: value });
  // }

  // const postData = async (e) => {
  //   e.preventDefault();

  //   const { name, email, phone, message } = feedback;

  // Access the "Feedback" collection
  // const usersRef = db.collection('Feedback');

  // Add a document to the "Feedback" collection
  // usersRef.add({
  //   name,
  //   email,
  //   phone,
  //   message
  // })
  //   .then((docRef) => {
  //     alert('FeedBack Posted Successfully!');
  //   })
  //   .catch((error) => {
  //     alert('Please Try Again Later ', error);
  //   });

  //   setFeedback({
  //     name: "",
  //     email: "",
  //     phone: "",
  //     message: ""
  //   })

  // }


  return (
    <div className='contact d-flex justify-content-center align-items-center'>
      <section className="mb-4 container"> {/* Added container for alignment */}
        <div className="row">
          <div className="col-md-9 mb-4"> {/* Adjusted for responsiveness */}
            <h2 className="h1-responsive font-weight-bold text-center my-4">Schedule Demo</h2>
            <form autoComplete="off" id="contact-form" name="contact-form" action="mail.php" method="POST">
              <div className="row">
                {/* Use col-12 for small screens and col-md-6 for medium to large screens */}
                <div className="col-12 col-md-6 mb-3">
                  <input type="text" id="name" name="name" className="form-control" placeholder='Your name' />
                </div>
                <div className="col-12 col-md-6 mb-3">
                  <input type="text" id="email" name="email" className="form-control" placeholder='Your email' />
                </div>
              </div>

              {/* This row is for the phone number, making it full width */}
              <div className="row">
                <div className="col-12 mb-3">
                  <input type="text" id="phone" name="phone" className="form-control" placeholder='Your Company name' />
                </div>
              </div>

              {/* Message textarea section */}
              <div className="row">
                <div className="col-12 mb-3">
                  <textarea id="message" name="message" placeholder='Your message' rows="3" className="form-control md-textarea"></textarea>
                </div>
              </div>

              <div className="text-center">
                <button className="btn btn2 btn-primary">Send</button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>

  )
}

export default Contact
