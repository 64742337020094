import React, { useEffect, useState } from 'react'
import './Header.css'
import { Link } from 'react-router-dom';
// import { useAuth0 } from '@auth0/auth0-react';

const Header = (props) => {


  const [smaller, setSmaller] = useState(false);
  const [shadow, setShadow] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isSmall = window.scrollY > 50;
      setSmaller(isSmall);
      setShadow(isSmall);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // const { loginWithRedirect, user, isAuthenticated, isLoading, logout } = useAuth0();
  // const { isLoading } = useAuth0();

  // const handleLogout = async () => {

  //   try {
  //     // Perform the logout
  //     await logout({ returnTo: window.location.origin });

  //     // Redirect to the home page
  //     window.location.replace('http://localhost:3000'); // Replace with your home page URL
  //   } catch (error) {
  //     console.error('Error during logout:', error);
  //   }
  // };

  // if (isLoading) {
  //   return (<div className="loader">
  //   </div>);
  // }
  return (
    <div className='header'>
      <nav className={`navbar navbar-expand-lg navbar-light bg-white navbar-custom ${smaller ? 'navbar-smaller' : ''} ${shadow ? 'navbar-shadow' : ''}`}>
        <div className="container-fluid">
          <Link className="navbar-brand" to="/"><b style={{ fontSize: '23px', marginLeft: '70px' }}>{props.compu}{props.teacher}</b></Link>
          <button
            style={{ marginRight: '40px' }}
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav me-auto">
              <li className="nav-item">
                <Link className="nav-link" aria-current="page" to="/">Hire Developers</Link>
              </li>
              <li className="nav-item">
              <Link className="nav-link applybtn" style={{ width: '125px' }} target="blank" aria-current="page" to="https://forms.gle/ZGrUY8oRo2zV6BiB9">Apply for jobs</Link>
              </li>
            </ul>
            <ul className="navbar-nav">
              {/* <li className="nav-item">
              <Link className="nav-link" to="/Login">Login</Link>
            </li> */}
              <li className="nav-item">
                <button type="button" style={{ marginRight: '80px' }}><a style={{textDecoration: 'none', color: 'white'}} href='/Contact'>Schedule Demo</a></button>
                {/* <Link className="nav-link" to="" style={{ marginRight: '80px' }}>Contact us</Link> */}
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  )
}

export default Header
