import React from 'react'
import './InfoSection.css'
import { Card, Container, Row, Col } from 'reactstrap';

const InfoSection = () => {
    const sections = [
        {
            title: 'Press',
            description: "What's up with Turing? Get the latest news about us here.",
            bgColor: 'blue',
        },
        {
            title: 'Blog',
            description: 'Know more about remote work. Checkout our blogs here.',
            bgColor: 'teal',
        },
        {
            title: 'Contact',
            description: "Have any questions? We'd love to hear from you.",
            bgColor: 'green',
        },
    ];

    return (
        <div className='infosection'>
            <Container>
                <Row>
                    {sections.map((section, index) => (
                        <Col key={index} lg={4} md={4} sm={12} className="mb-4">
                            <Card style={{ backgroundColor: section.bgColor, height: '200px' }}>
                            </Card>
                        </Col>
                    ))}
                </Row>
                {/* Rest of your component code */}
            </Container>
        </div>
    )
}

export default InfoSection
